import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../types/environment';
import { Rate } from '../../types/rate';

@Injectable({
  providedIn: 'root',
})
export class RateService {
  readonly API = `${this.env.apiUrl}/rateClassifier`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  add(rate: Rate) {
    return this.http.post<Rate>(`${this.API}/add`, rate);
  }

  getById(id: number) {
    return this.http.get<Rate>(`${this.API}/getById?id=${id}`);
  }

  getAll() {
    return this.http.get<Rate[]>(`${this.API}/getAll`);
  }

  getMapKey({
    cargoPlaceCode,
    flowerTypeId,
    imported,
  }: {
    cargoPlaceCode: Nullable<string | number>;
    flowerTypeId: Nullable<string | number>;
    imported: Nullable<boolean>;
  }) {
    return JSON.stringify({
      cargoPlaceCode: imported ? '' : cargoPlaceCode?.toString() ?? '',
      flowerTypeId: flowerTypeId?.toString() ?? '',
      imported: imported ?? false,
    });
  }

  toRateMap(value: Rate[]): Map<string, Rate> {
    return value.reduce((previousValue, currentValue) => {
      const key = this.getMapKey({
        cargoPlaceCode: currentValue.cargoPlaceCode ?? '',
        flowerTypeId: currentValue.flowerTypeId ?? '',
        imported: currentValue.imported ?? false,
      });
      return previousValue.set(key, currentValue);
    }, new Map());
  }

  getActualByType(rate: Rate) {
    return this.http.post<Nullable<Rate>>(`${this.API}/getActualByType`, rate);
  }
}
