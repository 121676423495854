import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../types/environment';
import { Client } from '../../types/client';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  readonly API = `${this.env.apiUrl}/client`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  save(client: Client) {
    return this.http.post<Client>(`${this.API}/save`, client);
  }

  getById(id: number) {
    return this.http.get<Client>(`${this.API}/getById?id=${id}`);
  }

  getAll() {
    return this.http.get<Client[]>(`${this.API}/getAll`);
  }

  toClientMap(value: Client[], key: keyof Client): Map<string, Client> {
    return value.reduce((previousValue, currentValue) => {
      return previousValue.set(currentValue[key]?.toString(), currentValue);
    }, new Map());
  }

  deleteById(id: number) {
    return this.http.post<void>(`${this.API}/deleteById?id=${id}`, null);
  }
}
