import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { Environment } from '../../types/environment';
import { Buyer } from '../../types/buyer';

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  readonly API = `${this.env.apiUrl}/buyer`;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  create(clientId: number) {
    return this.http.post<Buyer>(
      `${this.API}/create?clientId=${clientId}`,
      null,
    );
  }

  update(buyer: Buyer) {
    return this.http.post<Buyer>(`${this.API}/save`, buyer);
  }

  getById(id: number) {
    return this.http.get<Buyer>(`${this.API}/getById?id=${id}`);
  }

  getAll() {
    return this.http.get<Buyer[]>(`${this.API}/getAll`);
  }

  deleteById(id: number) {
    return this.http.post<void>(`${this.API}/deleteById?id=${id}`, null);
  }
}
